import React from 'react';
import NewsPageContent from "../../content/news-page/NewsPageContent"
import accessTokenService from "../../services/accessTokenService";
import requests from "../../requests";
import constants from "../../constants";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingPage from "../LoadingPage";
import userDataService from "../../services/userDataService";

class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news : [],
            loading: false
        }
    }

    async componentWillMount() {
        let space = await initialNewsPage();
        this.setState({
            news : space,
            loading: true
        })
    }

    render() {
        let loading = this.state.loading;
        let news = this.state.news;
        if(loading) {
            return (
                <div className="g-sidenav-show  bg-gray-100">
                    <Sidebar />
                    <div id="content">
                        <Navbar />
                        <div className="container" >
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary" href="/news/create-form">Thêm tin tức</a>
                                </div>
                                {
                                    Array.isArray(news) && news.length !== 0 ? (
                                            <NewsPageContent news={news} />
                                        ) :
                                        (<div>
                                            <p>Không có bài viết</p>
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <LoadingPage />
        }
    }
}

async function initialNewsPage() {
    accessTokenService.initialAccessToken();
    const userData = await userDataService.loadUserData();
    let responseData = await requests.getData(`/api/v1/news/manage-pageable-news?space=${userData.space}&pageable=publication_date,-1,1,1000`, constants.ACCESS_TOKEN);
    if (responseData.space) {
        return responseData.space;
    } else {
        return [];
    }
}

export default NewsPage;